select::-webkit-scrollbar-track,
.easy-pay-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #dfdfdf;
}
select::-webkit-scrollbar,
.easy-pay-scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: #dfdfdf;
}
select::-webkit-scrollbar-thumb,
.easy-pay-scroll::-webkit-scrollbar-thumb {
  background-color: #00a1d8; /*#00679c5d;*/
  border-radius: 10px;
}
.calc-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #00a1d8;
}
.calc-scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: #00a1d8;
}
.calc-scroll::-webkit-scrollbar-thumb {
  background-color: #dfdfdf; /*#00679c5d;*/
  border-radius: 10px;
}
.loading-select {
  -webkit-appearance: none;
  background: #ffffff
    url(https://uploads-ssl.webflow.com/60362f40a83dcf0034eb880b/604fdd781484d9192decddd2_Spin-1s-200px.gif)
    no-repeat center center;
  background-size: 40px 40px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  /* margin-left: -10px; */
  width: 100%;
  height: 10px;
  border-radius: 20px;
}
.range-slider-calc {
  background: rgb(37, 128, 219);
  background: -moz-linear-gradient(
    90deg,
    rgba(37, 128, 219, 1) 0%,
    rgba(254, 252, 1, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(37, 128, 219, 1) 0%,
    rgba(254, 252, 1, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(37, 128, 219, 1) 0%,
    rgba(254, 252, 1, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2580db",endColorstr="#ff0000",GradientType=1);
}
.range-slider-calc::-moz-progress-bar {
  -webkit-appearance: none;
  height: 5px;
  /*margin-left: -10px;*/
  width: 100%;
  background: linear-gradient(90deg, #e5e5e5 50%, #e5e5e5 50%);
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  z-index: 1;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  z-index: 1;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  z-index: 1;
}
input[type='range']::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff
    url('https://uploads-ssl.webflow.com/60362f40a83dcf0034eb880b/6229e58af4bfd184665fa57d_resize_24x24.png')
    no-repeat;
  cursor: grab;
  -webkit-appearance: none;
  margin-top: -8px;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
}
input[type='range']::-moz-range-thumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff
    url('https://uploads-ssl.webflow.com/60362f40a83dcf0034eb880b/6229e58af4bfd184665fa57d_resize_24x24.png')
    no-repeat;
  border: none;
  cursor: grab;
  -webkit-appearance: none;
  margin-top: -8px;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
}
input[type='range']::-ms-thumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff
    url('https://uploads-ssl.webflow.com/60362f40a83dcf0034eb880b/6229e58af4bfd184665fa57d_resize_24x24.png')
    no-repeat;
  cursor: grab;
  -webkit-appearance: none;
  margin-top: -8px;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
}
input[type='range'].slider-easy-pay::-webkit-slider-thumb {
  background: #565656;
}
input[type='range'].slider-easy-pay::-moz-range-thumb {
  background: #565656;
}
input[type='range'].slider-easy-pay::-ms-thumb {
  background: #565656;
}
input[type='range']::-webkit-slider-thumb:hover {
  transform: scale(1.175);
}
input[type='range']::-moz-range-thumb:hover {
  transform: scale(1.175);
}
input[type='range']::-ms-thumb:hover {
  transform: scale(1.175);
}
.cover {
  position: absolute;
  height: 11px;
  right: 0px;
  bottom: 5px;
  background: #005dbc; /*#1146bd;*/
  border-radius: 25px;
  z-index: 0;
  width: 76%;
  float: right;
}
.cover-easy-pay {
  background: #f1f1f1;
  border: 1px solid #ddd;
}
.range-slider-no-credit,
.range-slider-credit,
.range-slider-metrhta {
  background: #3fa8ff;
}
/* Firefox */
.range.slider-no-credit::-moz-progress-bar,
.range.slider-credit::-moz-progress-bar {
  -webkit-appearance: none;
  height: 5px;
  width: 100%;
  background: #3fa8ff;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
.hint {
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.show-hint {
  opacity: 1;
  pointer-events: all;
}
.pulse {
  position: absolute;
  z-index: 2;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;
}
.car-pulse {
  pointer-events: none;
  top: 50%;
  left: 40px;
}
.summary-pulse {
  cursor: pointer;
  top: 50%;
  left: 13%;
}
@media (max-width: 768px) {
  .car-pulse {
    top: 22px;
    left: 13%;
  }
  .summary-pulse {
    top: 27px;
    left: 10%;
  }
}
.pulse:before {
  content: '';
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #01a4e9;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulse:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #b3e8ff;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
#fileUploadContainer {
  border: 2px solid blue;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  padding: 50px;
  margin: 10px auto;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: relative;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}

.container-error {
  border-color: red !important;
}
.container-success {
  border-color: lightgreen !important;
}

.dz-drag-hover {
  border: 2px dashed rgb(0, 81, 255) !important;
}
.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.total-progress {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.total-progress .progress-bar {
  padding: 11px;
  width: 100%;
}
.total-progress div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.total-progress p {
  padding: 0;
  margin: 0;
}
.dz-image-preview:hover,
.dz-preview:hover {
  cursor: default;
}
.dz-image-preview:hover .dz-image-img,
.dz-preview:hover .dz-image-img {
  opacity: 0.4;
}
.dz-image-preview:hover .dz-details,
.dz-preview:hover .dz-details {
  opacity: 1;
}
.dz-image-preview:hover .dz-remove-icon,
.dz-preview:hover .dz-remove-icon {
  opacity: 1;
}

.dz-image-preview:hover .dz-size,
.dz-preview:hover .dz-size {
  background-color: rgba(255, 255, 255, 0.658);
}
.dz-size {
  font-size: 15px;
}

.dz-image-preview,
.dz-preview {
  position: relative;
}
.dz-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 12px;
  min-width: 100%;
  max-width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.dz-remove-icon {
  position: absolute;
  top: 35%;
  cursor: pointer;
  opacity: 0;
  width: 20px;
  height: 20px;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  transition: transform 150ms; /* Animation */
}
.dz-remove-icon:hover {
  transform: scale(1.06);
}
.file-upload-utils-container {
  pointer-events: none;
  /* position: absolute;
  bottom: 0px; */
}
.utils-msg {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  opacity: 0;
  padding: 7px;
  color: white;
  border-radius: 10px;
  position: absolute;
}
.error-msg {
  background-color: rgba(255, 0, 0, 0.603);
  font-size: 13px;
}
.success-msg {
  background-color: lightgreen;
  font-size: 15px;
  padding: 9px;
  font-weight: bold;
}

.w-lightbox-backdrop {
  z-index: 9999;
}

/* @media (max-width: 768px) {
  .dz-image-preview,
  .dz-preview {
    cursor: default;
  }
  .dz-image-preview .dz-details,
  .dz-preview .dz-details {
    opacity: 1;
  }
  .dz-image-preview .dz-remove-icon,
  .dz-preview .dz-remove-icon {
    opacity: 1;
  }
  .dz-remove-icon {
    top: 0;
    right: 0;
    z-index: 99;
  }
  .dz-image-preview .dz-size,
  .dz-preview .dz-size {
    background-color: rgba(255, 255, 255, 0.8);
  }
} */

#heroVideo {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background: transparent;
  min-width: 800px;
  min-height: 400px;
}

video {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.vjs-theme-city {
  --vjs-theme-city--primary: #0079af;
}

.vjs-control-bar {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.vjs-theme-city .vjs-progress-control .vjs-progress-holder {
  height: 5px;
}

.video-js .vjs-progress-control .vjs-mouse-display {
  width: 2px;
}

button.vjs-play-control.vjs-control:focus-visible,
video:focus-visible {
  outline: none;
}

#heroVideo .vjs-big-play-button {
  background: url(https://uploads-ssl.webflow.com/60362f40a83dcf0034eb880b/644a1fb9858d281c90c63aec_lovato-play-btn_v7.svg)
    no-repeat center center;
  transition: transform 250ms;
  border: none;
  scale: 2.5;
}
#heroVideo .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
}

@media (max-width: 940px) {
  #heroVideo {
    min-width: 500px;
    min-height: 300px;
  }
}
@media (max-width: 550px) {
  #heroVideo {
    min-width: 300px;
  }
  #heroVideo .vjs-big-play-button {
    scale: 2;
  }
}
@media (max-width: 350px) {
  #heroVideo {
    min-width: 200px;
  }
  #heroVideo .vjs-big-play-button {
    scale: 1.5;
  }
}

#heroVideo:hover .vjs-big-play-button {
  transform: scale(1.1);
}

.info-content {
  transition: max-height 0.6s ease-out;
}

#vehicle {
  scroll-margin-top: 0px;
}
